import { Box, Paper, Typography } from '@mui/material';
import { DataGridPro, GridColumns, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { CrudFilters, useNavigation, useResource } from '@refinedev/core';
import { CreateButton, DeleteButton, Show, useDataGrid } from '@refinedev/mui';

import { MODULE_CONTENT_MAP } from '@attackiq/constants';

import useCurrentResource from '../../hooks/useCurrentResource';
import { ModuleContent } from '../../types';

const ModuleShow = () => {
  const { id } = useResource();
  const { data: module } = useCurrentResource();
  const { dataGridProps, search } = useDataGrid<ModuleContent>({
    resource: 'module_contents',

    onSearch: search => {
      const filters: CrudFilters = [];

      filters.push({
        field: 'search',
        value: search,
        operator: 'eq'
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'search',
          value: '',
          operator: 'eq'
        }
      ],

      permanent: [
        {
          field: 'module_id',
          operator: 'eq',
          value: id
        }
      ]
    }
  });
  const { push, createUrl } = useNavigation();

  const columns: GridColumns<ModuleContent> = [
    {
      field: 'content_type',
      headerName: 'Model',
      renderCell: params => MODULE_CONTENT_MAP[params.row.content_type_model],
      flex: 1
    },
    {
      field: 'content_object__name',
      headerName: 'Name',
      renderCell: params => params.row.content_object?.name || params.row.content_object?.template_name,
      sortable: false,
      flex: 2
    },
    {
      field: 'content_object__description',
      headerName: 'Description',
      renderCell: params => params.row.content_object?.description || params.row.content_object?.template_description,
      sortable: false,
      flex: 2
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: params => <DeleteButton resource="module_contents" recordItemId={params.row.id} hideText />,
      sortable: false,
      maxWidth: 100
    }
  ];

  const handleAddContent = () => {
    const moduleContentsCreateUrl = createUrl('module_contents');

    push(`${moduleContentsCreateUrl}?module_id=${id}`);
  };

  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">Module ID: {id}</Typography>
          <Typography variant="subtitle2">Module Name: {module?.display_name}</Typography>
        </Box>
      </Paper>
      <Show
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <CreateButton variant="text" onClick={handleAddContent}>
                Add Content
              </CreateButton>
            </>
          );
        }}
      >
        <Typography variant="h5" sx={{ pb: 2 }}>
          Content:
        </Typography>
        <DataGridPro
          {...dataGridProps}
          filterModel={undefined}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          onFilterModelChange={({ quickFilterValues }) => {
            search(quickFilterValues?.join(' ') || '');
          }}
          disableColumnFilter
          autoHeight
        />
      </Show>
    </>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default ModuleShow;
