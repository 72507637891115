import React, { useMemo } from 'react';
import { Box, Card, Drawer, Typography } from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { DateField, useDataGrid } from '@refinedev/mui';

import ViewDiffButton from '../../history/components/ViewDiffButton';

type Props = {
  drawer: {
    visible: boolean;
    close: () => void;
    show: () => void;
  };
  selectedId: string | undefined;
  model: string;
};

const HistoryDrawer: React.FC<Props> = ({ drawer: { visible, close }, selectedId, model }) => {
  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'timestamp',
        headerName: 'DATE',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      { field: 'user', headerName: 'USER', flex: 1 },
      { field: 'type', headerName: 'EVENT', flex: 1 },
      {
        field: 'action',
        headerName: 'Actions',
        renderCell: params => {
          if (params.row.changes.length) {
            return <ViewDiffButton changes={params.row.changes} />;
          }

          return null;
        }
      }
    ],
    []
  );

  const { dataGridProps } = useDataGrid({
    resource: 'history',

    filters: {
      permanent: [
        {
          field: 'instance_id',
          operator: 'eq',
          value: selectedId as string
        },
        {
          field: 'model',
          operator: 'eq',
          value: model
        }
      ]
    }
  });

  return (
    <Drawer
      open={visible}
      anchor="right"
      onClose={close}
      PaperProps={{
        sx: {
          width: '40vw'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Box
          sx={{
            py: 2
          }}
        >
          <Typography variant="h6">History</Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1
          }}
        >
          <Card>
            <DataGridPro {...dataGridProps} columns={columns} autoHeight />
          </Card>
        </Box>
      </Box>
    </Drawer>
  );
};

export default HistoryDrawer;
