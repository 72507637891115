import { useMemo } from 'react';
import { Box, Button, Stack, Card, CardContent, TextField, CardHeader, Tooltip, Grid, Typography } from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { CrudFilters, getDefaultFilter, useMany } from '@refinedev/core';
import { DateField, DeleteButton, EditButton, List, useDataGrid } from '@refinedev/mui';
import { useModalForm } from '@refinedev/react-hook-form';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

import CreateAssessmentTemplateModal from './components/CreateAssessmentTemplateModal';
import ImportAssessmentTemplateModal from './components/ImportAssessmentTemplateModal';

const containsUUId = (string: string) => {
  const uuidRegExp = new RegExp(/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i);

  return uuidRegExp.test(string);
};

const DEFAULT_ICON_URL = '/static/dist/img/icon-template-custom.svg';
const getIconUrl = (icon: string) => {
  const isUUID = containsUUId(icon);

  if (icon === null) {
    return DEFAULT_ICON_URL;
  }

  if (isUUID) {
    return `/downloads/files/${icon}`;
  }

  return `/static/dist/img/${icon}`;
};

const AssessmentTemplateList = () => {
  const createModalFormProps = useModalForm({
    refineCoreProps: { action: 'create' }
  });

  const importModalFormProps = useModalForm({
    refineCoreProps: { action: 'create', resource: 'assessment_templates/import_json' }
  });

  const {
    modal: { show: showCreateModal }
  } = createModalFormProps;

  const {
    modal: { show: showImportModal }
  } = importModalFormProps;

  const { dataGridProps, search, filters } = useDataGrid({
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push({
        field: 'search',
        value: search,
        operator: 'eq'
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'search',
          value: '',
          operator: 'eq'
        }
      ]
    }
  });

  const projectTemplateTypeIds = dataGridProps.rows.map((item: any) => item.project_template_type);

  const { data: projectTemplateTypesData, isLoading: projectTemplateTypesIsLoading } = useMany({
    resource: 'assessment_template_types',
    ids: projectTemplateTypeIds,
    queryOptions: {
      enabled: projectTemplateTypeIds.length > 0
    }
  });

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'icon',
        headerName: 'Icon',
        sortable: false,
        renderCell: ({ value, row }: any) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px'
              }}
              component="picture"
            >
              <img
                style={{
                  width: '100%',
                  height: '100%'
                }}
                onError={(e: any) => {
                  e.target.remove();
                }}
                src={getIconUrl(value)}
                alt={`${row.project_name}icon`}
              />
            </Box>
          );
        }
      },
      {
        field: 'project_name',
        headerName: 'Name',
        width: 200,
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.project_name}>
              <Typography variant="body1" noWrap>
                {params.row.project_name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'template_description',
        headerName: 'Description',
        sortable: false,
        flex: 1
      },
      {
        field: 'project_template_type',
        headerName: 'Type',
        flex: 1,
        renderCell: ({ row }) => {
          if (projectTemplateTypesIsLoading) {
            return 'Loading...';
          }
          const projectTemplateType = projectTemplateTypesData?.data.find(
            (item: any) => item.id === row.project_template_type
          );

          return projectTemplateType?.name;
        }
      },
      {
        field: 'created',
        headerName: 'Created At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1
      },
      {
        field: 'last_updated',
        headerName: 'Updated At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ],
    // For some reason when listening to projectTemplateTypeData it triggers a second request
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectTemplateTypesIsLoading]
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Assessments templates"
                    placeholder="Search by name"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <List canCreate createButtonProps={{ onClick: () => showCreateModal() }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              py: 1
            }}
          >
            <Button variant="outlined" onClick={() => showImportModal()}>
              Import Template
            </Button>
          </Box>
          <DataGridPro {...dataGridProps} columns={columns} disableColumnMenu autoHeight filterModel={undefined} />
          <CreateAssessmentTemplateModal {...createModalFormProps} />
          <ImportAssessmentTemplateModal {...importModalFormProps} />
        </List>
      </Grid>
    </Grid>
  );
};

export default AssessmentTemplateList;
