import { useMemo, useState } from 'react';
import {
  Stack,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  TextField,
  Button,
  Tooltip,
  Typography,
  Checkbox
} from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { useMany, useModal, useUpdate } from '@refinedev/core';
import { CrudFilters, getDefaultFilter } from '@refinedev/core';
import { List, useDataGrid, EditButton, DeleteButton, ShowButton } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

import { CONNECTOR_TYPE } from '@attackiq/constants';

import ConnectorDetailsDrawer from './components/ConnectorDetailModal';

const ConnectorsList = () => {
  const { dataGridProps, filters, search } = useDataGrid({
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'search',
          operator: 'eq',
          value: ''
        }
      ]
    }
  });

  const vendorProductsIds = dataGridProps.rows.map(row => row.vendor_product);
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | undefined>(undefined);
  const viewDetailsDrawer = useModal();

  const { data: vendorProducts, isLoading: vendorsProductsIsLoading } = useMany({
    resource: 'vendor_products',
    ids: vendorProductsIds.filter(Boolean),
    queryOptions: {
      enabled: vendorProductsIds.length > 0
    }
  });

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.name}>
              <Typography variant="body1" noWrap>
                {params.row.name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'logo',
        headerName: 'Logo',
        width: 200,
        flex: 2,
        renderCell: ({ value, row }: any) => {
          if (vendorsProductsIsLoading) {
            return 'Loading...';
          }

          const vendorProduct = vendorProducts?.data.find(item => item.id === row.vendor_product);

          if (!vendorProduct || !vendorProduct.logo) {
            return null;
          }

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px'
              }}
              component="picture"
            >
              <img
                style={{
                  width: '100%',
                  height: '100%'
                }}
                src={vendorProduct?.logo}
                alt={`${vendorProduct?.name} logo`}
              />
            </Box>
          );
        }
      },
      {
        field: 'connector_type',
        headerName: 'Connector Type',
        width: 100,
        flex: 1,
        renderCell: ({ value }) => CONNECTOR_TYPE[value as keyof typeof CONNECTOR_TYPE]
      },
      {
        field: 'version',
        headerName: 'Version',
        width: 100,
        flex: 1
      },
      {
        field: 'is_deleted',
        headerName: 'Deleted',
        width: 100,
        renderCell: ({ row }) => <Deleted {...row} />
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
              <ShowButton
                hideText
                onClick={() => {
                  setSelectedConnectorId(params.row.id);
                  viewDetailsDrawer.show();
                }}
              />
            </Stack>
          );
        }
      }
    ],
    [vendorProducts, vendorsProductsIsLoading, viewDetailsDrawer]
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Search"
                    placeholder="Search by name"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <List
          canCreate
          createButtonProps={{
            children: 'Upload new connector'
          }}
        >
          <DataGridPro {...dataGridProps} filterModel={undefined} disableColumnMenu autoHeight columns={columns} />
          <ConnectorDetailsDrawer drawer={viewDetailsDrawer} selectedConnector={selectedConnectorId} />
        </List>
      </Grid>
    </Grid>
  );
};

const Deleted = ({ id, is_deleted }) => {
  const { mutate, isLoading } = useUpdate();

  return (
    <Checkbox
      checked={is_deleted}
      disabled={isLoading}
      onChange={(_event, is_deleted) => {
        mutate({ id, resource: 'connectors', values: { is_deleted } });
      }}
    />
  );
};

export default ConnectorsList;
