import { useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Tooltip,
  Select,
  TextField
} from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { CrudFilters, getDefaultFilter } from '@refinedev/core';
import { DateField, List, useDataGrid } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

import ViewDiffButton from './components/ViewDiffButton';

const MODEL_OPTIONS = [
  { value: 'Scenario', label: 'Scenario' },
  { value: 'ScenarioTemplate', label: 'Scenario Template' },
  { value: 'ProjectTemplate', label: 'Assessment Template' },
  { value: 'Phase', label: 'Phase' },
  { value: 'PhaseTag', label: 'Phase Tag' },
  { value: 'Mitigation', label: 'Mitigation' },
  { value: 'Connector', label: 'Connector' },
  { value: 'Vendor', label: 'Vendor' },
  { value: 'VendorProduct', label: 'Vendor Product' },
  { value: 'VendorProductTag', label: 'Vendor Product Tag' },
  { value: 'Module', label: 'Module' },
  { value: 'ModuleContent', label: 'Module Content' },
  { value: 'Tag', label: 'Tag' },
  { value: 'ScenarioTag', label: 'Scenario Tag' }
];

const HistoryList = () => {
  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'timestamp',
        headerName: 'Date',
        flex: 1,
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        sortable: false
      },
      {
        field: 'user',
        headerName: 'User',
        flex: 1,
        sortable: false
      },
      {
        field: 'instance_human_id',
        headerName: 'Name',
        flex: 1,
        sortable: false,
        renderCell: params => {
          return (
            <Tooltip title={params.row.instance_human_id}>
              <Typography variant="body2" noWrap>
                {params.row.instance_human_id}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'type',
        headerName: 'Action',
        flex: 1,
        sortable: false
      },
      {
        field: 'changes',
        headerName: 'Fields',
        valueFormatter: ({ value }) => {
          return value.map(({ field }: any) => field).join(', ');
        },
        flex: 1,
        sortable: false
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: params => {
          if (params.row.changes.length) {
            return <ViewDiffButton changes={params.row.changes} />;
          }

          return null;
        },
        sortable: false
      }
    ],
    []
  );

  const { dataGridProps, search, filters } = useDataGrid({
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { user, model, filter_out_no_user } = params;

      filters.push({
        field: 'user',
        operator: 'eq',
        value: user
      });

      filters.push({
        field: 'filter_out_no_user',
        operator: 'eq',
        value: filter_out_no_user ? true : undefined // if it's falsy, make it not be a part of the resulting query string
      });

      filters.push({
        field: 'model',
        operator: 'eq',
        value: model
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'model',
          operator: 'eq',
          value: 'Scenario'
        }
      ]
    }
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      model: getDefaultFilter('model', filters, 'eq') || '',
      user: getDefaultFilter('user', filters, 'eq') || '',
      filter_out_no_user: getDefaultFilter('filter_out_no_user', filters, 'eq') || ''
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="user"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="User"
                    placeholder="Search by user"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <FormControlLabel
                label="Hide default values"
                control={
                  <Controller
                    name="filter_out_no_user"
                    control={control}
                    render={({ field }) => {
                      return <Checkbox onChange={e => field.onChange(e.target.checked)} checked={!!field.value} />;
                    }}
                  />
                }
              />
              <Controller
                control={control}
                name="model"
                render={({ field }) => (
                  <FormControl margin="normal" size="small">
                    <InputLabel id="model-select">Model</InputLabel>
                    <Select {...field} labelId="model-select" label="Model">
                      {MODEL_OPTIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={9}>
        <List cardHeaderProps={{ title: 'History' }}>
          <DataGridPro {...dataGridProps} columns={columns} filterModel={undefined} autoHeight disableColumnMenu />
        </List>
      </Grid>
    </Grid>
  );
};

export default HistoryList;
