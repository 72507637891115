import { useMemo } from 'react';
import { CssBaseline, GlobalStyles, PaletteMode, ThemeOptions, ThemeProvider, alpha, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { DEFAULT_THEME, FONT_FAMILY, FONT_FAMILY_ROBOTO, FONT_FAMILY_SECONDARY } from '@attackiq/constants';

export const sharedThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: FONT_FAMILY,
    fontFamilySecondary: FONT_FAMILY_SECONDARY,
    roboto: FONT_FAMILY_ROBOTO,
    fontWeightMedium: 600,
    h1: {
      fontFamily: FONT_FAMILY_SECONDARY
    },
    h2: {
      fontFamily: FONT_FAMILY_SECONDARY
    },
    h3: {
      fontFamily: FONT_FAMILY_SECONDARY
    },
    h4: {
      fontFamily: FONT_FAMILY_SECONDARY
    },
    h5: {
      fontFamily: FONT_FAMILY_SECONDARY
    },
    h6: {
      fontFamily: FONT_FAMILY_SECONDARY
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1025,
      xl: 1536
    }
  },
  transitions: {
    duration: {
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
      short: 250,
      shorter: 200,
      shortest: 150,
      evenShortest: 100,
      standard: 300
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: 4
        }
      }
    }
  }
};

const darkTheme = createTheme(
  deepmerge(sharedThemeOptions, {
    palette: {
      mode: 'dark',
      primary: {
        main: '#29B6F6',
        selected: alpha('#29B6F6', 0.16),
        shadow: alpha('#29B6F6', 0.25),
        dark: '#039BE5'
      },
      secondary: {
        main: '#9FA8DA',
        light: '#B5A2E9',
        dark: '#4935C3'
      },
      error: {
        main: '#F44336',
        hover: alpha('#F44336', 0.08)
      },
      success: {
        main: '#66BB6A',
        light: '#81C784',
        dark: '#388E3C',
        hover: alpha('#66BB6A', 0.08)
      },
      info: {
        main: '#29B6F6',
        dark: '#0288D1',
        light: '#4FC3F7',
        selected: alpha('#29B6F6', 0.16)
      },
      warning: {
        main: '#FFA726',
        hover: alpha('#FFA726', 0.08)
      },
      background: {
        default: '#121722',
        paper: '#212939',
        paperElevation8: '#242D3E',
        paperElevation14: '#242D3E',
        paperElevation15: '#273143',
        paperElevation24: '#2A3446',
        tooltip: alpha('#616161', 0.92)
      },
      action: {
        selectedOpacity: 0.16,
        active: alpha('#FFFFFF', 0.56),
        hover: alpha('#FFFFFF', 0.08),
        disabled: alpha('#FFFFFF', 0.38)
      }
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none'
          }
        }
      }
    }
  } satisfies ThemeOptions)
);
const lightTheme = createTheme(deepmerge(sharedThemeOptions, DEFAULT_THEME satisfies ThemeOptions));

const cache = createCache({
  key: 'ai',
  prepend: true // prepend ensures that @emotion/css classnames are injected after @mui/material and @emotion/react ones
});

interface AiAppThemeProps {
  children: React.ReactNode;
  mode?: PaletteMode;
}

export function AiAppTheme({ children, mode = 'light' }: AiAppThemeProps) {
  const theme = useMemo(() => (mode === 'dark' ? darkTheme : lightTheme), [mode]);

  window['theme'] = theme;

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <GlobalStyles
          styles={{
            body: {
              width: '100vw',
              height: '100vh',

              '& #root': {
                height: '100%',
                width: '100%'
              }
            },
            a: {
              textDecoration: 'none'
            },
            'ol, ul': {
              paddingInlineStart: 24
            }
          }}
        />

        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}
